import {useParams} from 'next/navigation';
import {error} from '@/logger/client-logger';
import {slugFromRelayId} from '@/utils/network-parser';
import {Path} from './path-constants';

type BaseLocaleArgs = {
  locale?: string;
};

export type BaseRootNetworkArgs = BaseLocaleArgs & {
  slug?: string;
};

type NetworkItemArgs = BaseRootNetworkArgs & {id: string};

export type GetPathParameters =
  | [path: Path.SignIn]
  | [path: Path.SignUp]
  | [path: Path.Lists]
  | [path: Path.Home]
  | [path: Path.ResetPassword]
  | [path: Path.ConfirmDetails]
  | [path: Path.TeamSetup]
  | [path: Path.OnboardingInvite, parameters: BaseRootNetworkArgs]
  | [path: Path.OnboardingSyncNetwork, parameters: BaseRootNetworkArgs]
  | [path: Path.NetworkHome, parameters?: BaseRootNetworkArgs]
  | [path: Path.People, parameters?: BaseRootNetworkArgs]
  | [path: Path.Sources, parameters?: BaseRootNetworkArgs]
  | [path: Path.Folder, parameters: NetworkItemArgs]
  | [path: Path.Space, parameters: NetworkItemArgs]
  | [path: Path.PersonListDefault, parameters: NetworkItemArgs]
  | [path: Path.PersonListTable, parameters: NetworkItemArgs]
  | [path: Path.PersonListBoardDefault, parameters: NetworkItemArgs]
  | [path: Path.PersonListBoard, parameters: NetworkItemArgs & {personalizedFieldId: string}]
  | [path: Path.SettingsMyDetails, parameters?: BaseRootNetworkArgs]
  | [path: Path.SettingsNetworkDetails, parameters?: BaseRootNetworkArgs]
  | [path: Path.SettingsNetworkSyncing, parameters?: BaseRootNetworkArgs]
  | [path: Path.SettingsNetworkMembers, parameters?: BaseRootNetworkArgs]
  | [path: Path.SettingsNetworkImports, parameters?: BaseRootNetworkArgs]
  | [path: Path.PublicPersonTable, parameters: BaseLocaleArgs & {publicIdentifier: string}]
  | [path: Path.Activity, parameters?: BaseRootNetworkArgs]
  | [path: Path.SignUpWithEmail, parameters: BaseLocaleArgs & {email: string}]
  | [path: Path.VerifyEmail, parameters: BaseLocaleArgs & {email: string}];

export type GetPersonListDefaultPathParameters = {
  id: string;
  defaultView?:
    | {
        __typename:
          | 'PersonListNetworkItem'
          | 'CustomField'
          | 'PersonalizedFieldSingleSelectStringOption';
        id: string;
      }
    | {__typename: '%other'}
    | null;
  rootSlug?: string;
};

export function useGetPath(basePath?: string) {
  const {locale, slug} = useParams<{locale: string; slug: string}>();

  const baseParameters: Record<string, string> = {
    locale,
  };

  if (slug) {
    baseParameters.slug = slug;
  }

  function getPath(...args: GetPathParameters): string {
    const allParameters = {
      ...baseParameters,
      ...args[1],
    };

    let finalPath: string = args[0];

    Object.entries(allParameters).forEach(([key, value]) => {
      finalPath = finalPath.replace(`{${key}}`, value as string);
    });

    return basePath ? `${basePath}${finalPath}` : finalPath;
  }

  function getPersonListDefaultPath({
    id,
    defaultView,
    rootSlug,
  }: GetPersonListDefaultPathParameters) {
    const listSlug = slugFromRelayId(id);

    let options: {
      id: string;
      personalizedFieldId?: string;
      slug?: string;
    } = {id: listSlug};

    if (rootSlug) {
      options = {...options, slug: rootSlug};
    }

    if (defaultView?.__typename === 'PersonListNetworkItem') {
      return getPath(Path.PersonListTable, options);
    }

    if (
      defaultView?.__typename === 'CustomField' ||
      defaultView?.__typename === 'PersonalizedFieldSingleSelectStringOption'
    ) {
      return getPath(Path.PersonListBoard, {...options, personalizedFieldId: defaultView.id});
    }

    // We couldn't figure it out. Hit the naked route and redirect.
    return getPath(Path.PersonListDefault, options);
  }

  function getNetworkItemPath({id, type}: {id: string; type: string}) {
    const slug = slugFromRelayId(id);

    if (type === 'PersonListNetworkItem') {
      return getPath(Path.PersonListTable, {id: slug});
    }
    if (type === 'FolderNetworkItem') {
      return getPath(Path.Folder, {id: slug});
    }
    if (type === 'NetworkSpace') {
      return getPath(Path.Space, {id: slug});
    }

    error(new Error('getNetworkItemPath called with unexpected network type'), undefined, {
      type,
      id,
    });

    return getPath(Path.NetworkHome);
  }

  return {
    getPath,
    getNetworkItemPath,
    getPersonListDefaultPath,
  };
}
